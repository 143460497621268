*::-webkit-scrollbar {
  scrollbar-gutter: stable;
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: hsl(var(--accent));
  visibility: visible;
}
/* *:hover::-webkit-scrollbar-thumb {
  visibility: visible;
} */

.date_option div {
  width: 0;
  height: 0;
  padding: 0;
  border: none;
  visibility: hidden;
}
.date_option div a {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  border: none;
}
.rs-picker-toggle-placeholder,
.date_option div a input,
.date_option div a svg {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  border: none;
}

section option {
  font-family: var(--amazonRegular);
}
